import React, { useRef, useEffect, useCallback } from 'react';
import CircleArrow from '../assets/svgs/circle-arrow.svg';
import { gsap } from 'gsap';
import {Link} from "gatsby";

const getColors = (color = "secondary") => {
  switch (color.toString().toLowerCase()) {
    case "secondary":
      return {
        backgroundColor: "bg-secondary",
        textColor: "text-light"
      };
    case "light":
      return {
        backgroundColor: "bg-light",
        textColor: "text-secondary"
      }
    case "health":
      return {
        backgroundColor: "bg-health",
        textColor: "text-secondary"
      }
    case "commercial":
      return {
        backgroundColor: "bg-commercial",
        textColor: "text-secondary"
      }
    case "education":
      return {
        backgroundColor: "bg-education",
        textColor: "text-white"
      }
    case "facilities":
      return {
        backgroundColor: "bg-facilities",
        textColor: "text-white"
      }
    default:
      return {
        backgroundColor: "bg-secondary",
        textColor: "text-light"
      };
  }
};

const Button = ({ text, to = "/", onClick = null, color = "secondary", iconOnlyOnMobile = false, textClassName, isExternal = false, fixedWidth = false, responsive = false, iconPrefix = false, flipIcon = false, extraSmallOnMobile = false }) => {
  const buttonRef = useRef(null);
  const gapCoverRef = useRef(null);
  const iconRef = useRef(null);
  const textRef = useRef(null);
  const { backgroundColor, textColor } = getColors(color);

  const animate = useCallback((width) => {
    gsap.to(gapCoverRef.current, {
      width,
      duration: 0.3,
      ease: 'power2.inOut'
    });
  }, []);

  const handleInteraction = useCallback(() => {
    let width = textRef.current.offsetWidth;
    if (fixedWidth){
      width = buttonRef.current.offsetWidth - iconRef.current.offsetWidth - 4;
    }

    animate(width);
  }, [animate]);

  const handleBlur = useCallback(() => {
    animate('100%');
  }, [animate]);

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) return;

    gsap.set(gapCoverRef.current, { width: '100%' });

    button.addEventListener('focus', handleInteraction);
    button.addEventListener('blur', handleBlur);

    return () => {
      button.removeEventListener('focus', handleInteraction);
      button.removeEventListener('blur', handleBlur);
    };
  }, [handleInteraction, handleBlur]);

  const commonProps = {
    ref: buttonRef,
    onMouseEnter: handleInteraction,
    onMouseLeave: handleBlur,
    className: `inline-flex items-stretch overflow-hidden gap-[4px] rounded-full cursor-pointer h-12 relative focus:outline-none ${responsive && "h-8 desktop:h-12"}`,
  };

  const content = (
    <>
      <div ref={gapCoverRef} className={`absolute ${backgroundColor} h-full w-full rounded-full ${iconOnlyOnMobile ? "hidden tablet:block" : "block"} ${iconPrefix && "right-0"}`} />

      {iconPrefix && (
        <div ref={iconRef}
             className={`flex items-center justify-center size-12 rounded-full relative ${backgroundColor} ${responsive && "!size-8 desktop:!size-12"} ${flipIcon && "scale-x-[-1]"}`}>
          <CircleArrow className={`size-8 ${textColor} ${responsive && "!size-6 desktop:!size-8"}`}/>
        </div>
      )}

      <div className="flex-grow flex items-center">
        <div
          ref={textRef}
          className={`flex items-center font-medium px-7 text-lg rounded-full ${textColor} ${backgroundColor} ${iconOnlyOnMobile ? "hidden tablet:flex" : "flex"} ${responsive && "px-3.5 desktop:px-7 text-sm desktop:text-lg"} ${extraSmallOnMobile && "px-2 text-xs desktop:px-7 desktop:text-lg"} ${textClassName}`}
        >
          <span className="relative whitespace-nowrap">
            {text}
          </span>
        </div>
      </div>

      {!iconPrefix && (
        <div ref={iconRef}
             className={`flex items-center justify-center size-12 rounded-full relative ${backgroundColor} ${responsive && "!size-8 desktop:!size-12"}`}>
          <CircleArrow className={`size-8 ${textColor} ${responsive && "!size-6 desktop:!size-8"}`}/>
        </div>
      )}
    </>
  );

  return isExternal ? (
    <a href={to} {...commonProps} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  ) : onClick ? (
    <button onClick={onClick} {...commonProps}>
      {content}
    </button>
  ): (
    <Link to={to} {...commonProps}>
      {content}
    </Link>
  );
};

export default Button;