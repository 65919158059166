import { css } from "styled-components";
import { ThemeType, grommet } from "grommet";
import * as utils from "grommet/utils";

const { deepMerge } = utils;

export const theme = deepMerge(grommet, {
  name: "Blike",
  rounding: 4,
  spacing: 22,
  defaultMode: "light",
  global: {
    focus: {
      outline: {
        color: "transparent",
      },
    },
    colors: {
      brand: {
        dark: "#06303B",
        light: "#06303B",
      },
      "brand-secondary": {
        dark: "#0f4c3d",
        light: "#0f4c3d",
      },
      "brand-third": {
        dark: "#6fff6f",
        light: "#6fff6f",
      },
      background: {
        dark: "#D9D9D9",
        light: "#D9D9D9",
      },
      "background-back": {
        dark: "#111111",
        light: "#EEEEEE",
      },
      "background-front": {
        dark: "#222222",
        light: "#222222",
      },
      "background-contrast": {
        dark: "#FFFFFF11",
        light: "#11111111",
      },
      text: {
        dark: "#000000",
        light: "#000000",
      },
      "text-strong": {
        dark: "#FFFFFF",
        light: "#000000",
      },
      "text-weak": {
        dark: "#999999",
        light: "#999999",
      },
      "text-xweak": {
        dark: "#999999",
        light: "#999999",
      },
      border: {
        dark: "#444444",
        light: "#CCCCCC",
      },
      control: "brand",
      "active-background": "background-contrast",
      "active-text": "text-strong",
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#C64402",
      "status-warning": "#FFAA15",
      "status-ok": "#00C781",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning",
      focus: {
        light: "brand",
        dark: "blue",
      },
    },
    font: {
      family: "Theinhardt",
      size: "18px",
      height: "28px",
      maxWidth: "588px",
      face: "Theinhardt",
    },
    active: {
      background: "active-background",
      color: "active-text",
    },
    hover: {
      background: "active-background",
      color: "active-text",
    },
    selected: {
      background: "selected-background",
      color: "selected-text",
    },
    borderSize: {
      xsmall: "1px",
      small: "2px",
      medium: "5px",
      large: "14px",
      xlarge: "28px",
    },
    breakpoints: {
      xxsmall: {
        value: 320,
      },
      xsmall: {
        value: 480,
      },
      small: {
        value: 896,
        borderSize: {
          xsmall: "1px",
          small: "2px",
          medium: "5px",
          large: "7px",
          xlarge: "14px",
        },
        edgeSize: {
          none: "0px",
          hair: "1px",
          xxsmall: "2px",
          xsmall: "4px",
          small: "7px",
          medium: "14px",
          large: "28px",
          xlarge: "56px",
        },
        size: {
          xxsmall: "28px",
          xsmall: "56px",
          small: "112px",
          medium: "224px",
          large: "448px",
          xlarge: "896px",
          full: "100%",
        },
      },
      medium: {
        value: 1360,
      },
      large: {},
    },
    edgeSize: {
      none: "0px",
      hair: "1px",
      xxsmall: "4px",
      xsmall: "9px",
      small: "14px",
      medium: "28px",
      large: "78px",
      xlarge: "112px",
      responsiveBreakpoint: "small",
    },
    input: {
      padding: "14px",
      weight: 600,
    },
    spacing: "28px",
    size: {
      xxsmall: "56px",
      xsmall: "112px",
      small: "224px",
      medium: "448px",
      large: "896px",
      xlarge: "1344px",
      xxlarge: "1792px",
      maxWidth: "1320px",
      full: "100%",
    },
  },
  chart: {},
  diagram: {
    line: {},
  },
  layer: {
    extend: (props) =>
      props.position === "right" &&
      css`
        z-index: 9999999;
      `,
    // zIndex: 9999999,
    border: {
      radius: "0px",
    },
  },
  meter: {},
  formField: {
    focus: {
      border: {
        color: "transparent !important"
      }
    },
    border: {
      color: "transparent !important",
      error: {
        color: {
          dark: "white",
          light: "status-critical",
        },
      },
      position: "inner",
      side: "all",
      style: "solid",
      size: "xsmall",
    },
    content: {
      pad: "small",
    },
    disabled: {
      background: {
        color: "status-disabled",
        opacity: "medium",
      },
    },
    error: {
      color: "status-critical",
      size: "xsmall",
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
    },
    help: {
      color: "active-text",
      margin: {
        start: "small",
      },
      size: "medium",
    },
    info: {
      size: "xsmall",
      color: "text-xweak",
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
    },
    label: {
      margin: {
        vertical: "xsmall",
        horizontal: "small",
      },
      size: "small",
      weight: "normal",
      color: "brand",
    },
    margin: {
      bottom: "none",
    },
  },
  textInput: {
    extend: css`
      padding: 5px 0;
      border-color: transparent;
      border-radius: 8px;
      font-family: "Theinhardt";
      font-weight: 400;
      font-size: 26px;
      color: white;
      &::placeholder {
        font-weight: 400;
      }
    `,
  },
  textArea: {
    extend: css`
      padding: 10px 0;
      font-size: 26px;
      border-color: transparent;
      border-radius: 8px;
      font-family: "Theinhardt";
      font-weight: 400;
      color: white;
      &::placeholder {
        font-weight: 400;
      }
    `,
  },
  accordion: {
    border: {
      color: "transparent",
    },
  },
  button: {
    default: {
      extend: css`
        /* color: white; */
      `,
    },
    extend: ({ sizeProp }) => {
      let fontSize;

      // switch (sizeProp) {
      //   case 'xsmall':
      //     fontSize = '12px'
      //     break
      //   case 'small':
      //     fontSize = '16px'
      //     break
      //   case 'medium':
      //     fontSize = '18px'
      //     break
      //   default:
      //     fontSize = '21px'
      // }

      return {
        // fontSize,
        fontWeight: 400,
      };
    },

    // default: grommet.button.default,
    primary: {
      color: "brand-secondary",
      background: {
        color: "brand",
      },
      extend: css`
        background-color: #6fff6f;
        color: #0f4c3d;
      `,
    },
    secondary: {
      extend: css`
        color: "white";
      `,
    },
    active: {
      background: { color: "black" },
      color: "black",
      secondary: {
        background: "black",
        color: "white",
      },
    },
    border: {
      width: "1px",
      radius: "15px",
    },
    padding: {
      vertical: "8px",
      horizontal: "26px",
    },
    size: {
      large: {
        border: {
          radius: "24px",
        },
        pad: {
          vertical: "10px",
          horizontal: "42px",
        },
      },
      small: {
        border: {
          radius: "20px",
        },
        pad: {
          vertical: "4px",
          horizontal: "16px",
        },
      },
      xsmall: {
        border: {
          radius: "20px",
        },
        pad: {
          vertical: "0px",
          horizontal: "16px",
        },
      },
    },
    // extend: (props) => console.log(props)
  },
  anchor: {
    fontWeight: 400,
    // extend: (props) => {
    //   console.log("props", props);
    // }
  },
  calendar: {
    small: {
      fontSize: "17.266666666666666px",
      lineHeight: 1.375,
      daySize: "32.00px",
    },
    medium: {
      fontSize: "21px",
      lineHeight: 1.45,
      daySize: "64.00px",
    },
    large: {
      fontSize: "32.2px",
      lineHeight: 1.11,
      daySize: "128.00px",
    },
  },
  checkBox: {
    size: "28px",
    toggle: {
      radius: "28px",
      size: "56px",
    },
  },
  clock: {
    analog: {
      hour: {
        width: "9px",
        size: "28px",
      },
      minute: {
        width: "5px",
        size: "14px",
      },
      second: {
        width: "4px",
        size: "11px",
      },
      size: {
        small: "84px",
        medium: "112px",
        large: "168px",
        xlarge: "252px",
        huge: "336px",
      },
    },
    digital: {
      text: {
        xsmall: {
          size: "13.533333333333331px",
          height: 1.5,
        },
        small: {
          size: "17.266666666666666px",
          height: 1.43,
        },
        medium: {
          size: "21px",
          height: 1.375,
        },
        large: {
          size: "24.733333333333334px",
          height: 1.167,
        },
        xlarge: {
          size: "28.46666666666667px",
          height: 1.1875,
        },
        xxlarge: {
          size: "35.93333333333334px",
          height: 1.125,
        },
      },
    },
  },
  heading: {
    font: {
      family: "Theinhardt",
    },
    color: "brand",
    weight: "400",
    level: {
      1: {
        font: {
          family: "Theinhardt-Bold",
        },
        xxsmall: {
          size: "42px",
          height: "1",
          maxWidth: "1006px",
        },
        xsmall: {
          size: "46px",
          height: "1",
          maxWidth: "1006px",
        },
        small: {
          size: "76px",
          height: "1",
          maxWidth: "1006px",
        },
        medium: {
          size: "98px",
          height: "1",
          maxWidth: "3097px",
        },
        large: {
          size: "121px",
          height: "0.9",
          maxWidth: "3097px",
        },
        xlarge: {
          size: "121px",
          height: "0.9",
          maxWidth: "3097px",
        },
      },
      2: {
        font: {
          family: "Theinhardt-Bold",
        },
        small: {
          size: "36px",
          height: "1.1",
          maxWidth: "902px",
        },
        medium: {
          size: "48px",
          height: "1.1",
          maxWidth: "1215px",
        },
        large: {
          size: "86px",
          height: "1.1",
          maxWidth: "1529px",
        },
        xlarge: {
          size: "121px",
          height: "100px",
          maxWidth: "3097px",
        },
      },
      3: {
        font: {
          family: "Theinhardt-Medium",
        },
        small: {
          size: "28px",
          height: "35px",
          maxWidth: "797px",
        },
        medium: {
          size: "36px",
          height: "43px",
          maxWidth: "1006px",
        },
        large: {
          size: "43px",
          height: "50px",
          maxWidth: "1215px",
        },
        xlarge: {
          size: "91px",
          height: "74px",
          maxWidth: "1424px",
        },
      },
      4: {
        font: {
          family: "Theinhardt-Medium",
        },
        small: {
          size: "21px",
          height: "1",
          maxWidth: "693px",
        },
        medium: {
          size: "28px",
          height: "1",
          maxWidth: "797px",
        },
        large: {
          size: "36px",
          height: "1",
          maxWidth: "902px",
        },
        xlarge: {
          size: "36px",
          height: "43px",
          maxWidth: "1006px",
        },
      },
      5: {
        small: {
          size: "16px",
          height: "1",
          maxWidth: "536px",
        },
        medium: {
          size: "16px",
          height: "1",
          maxWidth: "536px",
        },
        large: {
          size: "16px",
          height: "1",
          maxWidth: "536px",
        },
        xlarge: {
          size: "16px",
          height: "1",
          maxWidth: "536px",
        },
      },
      6: {
        small: {
          size: "17px",
          height: "24px",
          maxWidth: "483px",
        },
        medium: {
          size: "17px",
          height: "24px",
          maxWidth: "483px",
        },
        large: {
          size: "17px",
          height: "24px",
          maxWidth: "483px",
        },
        xlarge: {
          size: "17px",
          height: "24px",
          maxWidth: "483px",
        },
      },
    },
  },
  paragraph: {
    font: {
      family: "Theinhardt-Medium",
    },
    // extend: () => {
    //   return {
    //     letterSpacing: '-0.03rem',
    //   }
    // },
    // extend: props => {
    //   return {
    //     letterSpacing: '-0.03rem',
    //     fontFamily:
    //       props.size !== 'large' ? 'Theinhardt' : 'Theinhardt',
    //   }
    // },
    xsmall: {
      size: "12px",
      height: "1.45",
      maxWidth: "784px",
      marginBottom: "25px",
    },
    small: {
      size: "14px",
      height: "1.45",
      maxWidth: "784px",
      marginBottom: "25px",
    },
    medium: {
      size: "18px",
      height: "1.45",
      maxWidth: "784px",
      marginBottom: "25px",
    },
    large: {
      size: "28px",
      height: "1",
      maxWidth: "784px",
      marginBottom: "25px",
    },
  },
  radioButton: {
    size: "28px",
  },
  text: {
    xsmall: {
      size: "12px",
      height: "18px",
      maxWidth: "483px",
    },
    small: {
      size: "16px",
      height: "21px",
      maxWidth: "536px",
    },
    medium: {
      size: "18px",
      height: "28px",
      maxWidth: "588px",
    },
    large: {
      size: "18px",
      height: "32px",
      maxWidth: "693px",
    },
    xlarge: {
      size: "18px",
      height: "35px",
      maxWidth: "797px",
    },
    xxlarge: {
      size: "18px",
      height: "43px",
      maxWidth: "1006px",
    },
  },
});

// switch (sizeProp) {
//   case 'xsmall':
//     fontSize = '12px'
//     break
//   case 'small':
//     fontSize = '16px'
//     break
//   case 'medium':
//     fontSize = '18px'
//     break
//   default:
//     fontSize = '21px'
// }
