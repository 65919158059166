import React, {useEffect, useRef, useMemo, useCallback, useState} from "react";
import { StaticImage } from "gatsby-plugin-image";

import SafetyBadge from "../assets/svgs/safety2.svg";
import LinkedIn from "../assets/svgs/socialLinkedIn.svg";
import Instagram from "../assets/svgs/socialInstagram.svg";
import X from "../assets/svgs/socialX.svg";
import BauvillHealthLogo from "../assets/svgs/health-no-icon.svg";
import BauvillCommercialLogo from "../assets/svgs/commercial-no-icon.svg";
import BauvillEducationLogo from "../assets/svgs/education-no-icon.svg";
import BauvillFacilitiesLogo from "../assets/svgs/facilities-no-icon.svg";
import LinkArrowShort from "../assets/svgs/link-arrow-short.svg";
import LinkArrow from "../assets/svgs/link-arrow.svg";

import gsap from "gsap";

import AnimatedLink from "./AnimatedLink";
import Button from "./Button";
import {Link} from "gatsby";
import AnimatedLogo from "./AnimatedLogo";

const SOCIAL_MEDIA_LINKS = {
  linkedin: "https://www.linkedin.com/company/bauvill-ltd./",
  x: "https://x.com/bauvill",
  instagram: "https://www.instagram.com/bauvill_ltd/",
};

const DepartmentLink = ({ department, logo: Logo }) => {
  const iconRef = useRef(null);

  function animateIn() {
    console.log("animateIn");
    gsap.to(iconRef.current, {
      duration: 0.2,
      x: 0
    });
  }

  function animateOut() {
    gsap.to(iconRef.current, {
      duration: 0.2,
      x: -20
    });
  }

  return (
    <Link
      onMouseEnter={animateIn}
      onMouseLeave={animateOut}
      to={`/${department}`}
      className="block self-start w-full cursor-pointer tablet:w-auto"
    >
      <Logo className="h-6 pr-[20px] tablet:h-auto" />

      <div className="hidden relative w-full mt-2 overflow-hidden tablet:block">
        <div ref={iconRef} className="w-full" style={{ transform: "translateX(-20px)"}}>
          <LinkArrowShort className="text-light w-full" />
        </div>
      </div>

      <div className={"block tablet:hidden"}>
        <LinkArrow />
      </div>
    </Link>
  );
};

const SocialMediaLink = ({ platform, url }) => {
  let Icon;
  switch (platform) {
    case "linkedin":
      Icon = LinkedIn;
      break;
    case "x":
      Icon = X;
      break;
    case "instagram":
    default:
      Icon = Instagram;
  }

  return (
    <a
      className="text-light h-6 w-8 *:h-full tablet:w-10 tablet:h-8"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={`Visit our ${platform} page`}
    >
      <span className={"size-6 *:w-full *:h-full *:transition-[padding] *:duration-200 *:pr-2 hover:*:pl-2 hover:*:pr-0 tablet:size-8"}>
        <Icon fill="currentColor" />
      </span>
    </a>
  );
};

const useScrollEffect = (footerRef, contentRef, setEnableAnimation) => {
  const rafRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  const updateFooterPosition = useCallback(() => {
    const footer = footerRef.current;
    const content = contentRef.current;
    if (!footer || !content) return;

    const footerRect = footer.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const footerTop = footerRect.top;
    const footerHeight = footerRect.height;

    // Check if any part of the footer is in view
    const isInView = footerTop < windowHeight;

    setIsFixed(isInView);

    if (isInView) {
      let translateY;
      if (footerTop >= windowHeight) {
        translateY = 100;
      } else if (footerTop + footerHeight <= windowHeight) {
        translateY = 0;
      } else {
        const visiblePortion = windowHeight - footerTop;
        const visiblePercentage = (visiblePortion / footerHeight) * 100;
        translateY = 100 - visiblePercentage;
      }

      setEnableAnimation(translateY <= 75);
    }
  }, [footerRef, contentRef, setEnableAnimation]);

  const animateFooter = useCallback(() => {
    updateFooterPosition();
    rafRef.current = requestAnimationFrame(animateFooter);
  }, [updateFooterPosition]);

  useEffect(() => {
    const startAnimation = () => {
      if (!rafRef.current) {
        rafRef.current = requestAnimationFrame(animateFooter);
      }
    };

    const stopAnimation = () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
        rafRef.current = null;
      }
    };

    startAnimation();

    window.addEventListener('resize', startAnimation);
    window.addEventListener('orientationchange', startAnimation);
    document.addEventListener('DOMContentLoaded', startAnimation);

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        stopAnimation();
      } else {
        startAnimation();
      }
    });

    return () => {
      stopAnimation();
      window.removeEventListener('resize', startAnimation);
      window.removeEventListener('orientationchange', startAnimation);
      document.removeEventListener('DOMContentLoaded', startAnimation);
    };
  }, [animateFooter]);

  return [updateFooterPosition, isFixed];
};

const Footer = () => {
  const footerRef = useRef(null);
  const contentRef = useRef(null);
  const [enableAnimation, setEnableAnimation] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [updateFooterPosition, isFixed] = useScrollEffect(footerRef, contentRef, setEnableAnimation);

  const departmentLinks = useMemo(() => [
    { department: "health", logo: BauvillHealthLogo },
    { department: "commercial", logo: BauvillCommercialLogo },
    { department: "education", logo: BauvillEducationLogo },
    { department: "facilities", logo: BauvillFacilitiesLogo },
  ], []);

  useEffect(() => {
    const handleLoad = () => {
      setPageLoaded(true);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <footer ref={footerRef} className={`bg-secondary w-full overflow-hidden relative z-0 h-screen ${pageLoaded ? 'opacity-100' : 'opacity-0'}`}>
      <div ref={contentRef} className={`flex flex-col justify-between w-full py-4 px-4 text-light h-screen fixed bottom-0 ${isFixed ? 'fixed bottom-0' : 'opacity-0'} desktop:py-16 desktop:px-12`}>
        <nav className="grid grid-cols-1 justify-between w-full desktop:gap-24 desktop:grid-cols-2">
          <div className="grid grid-cols-1 tablet:flex tablet:gap-10">
            {departmentLinks.map((link) => (
              <DepartmentLink key={link.department} {...link} />
            ))}
          </div>

          <div className={"grid grid-cols-1 gap-5 w-full tablet:w-full xl:max-w-[600px] ml-auto"}>
            <div className="hidden grid-cols-2 gap-8 *:*:text-light *:*:font-medium desktop:grid">
              <div>
                <AnimatedLink to="mail:enquiries@bauvill.co.uk" text="enquiries@bauvill.co.uk" isExternal={true}/>
                <AnimatedLink to="tel:+441622231490" text="01622 231490" isExternal={true}/>
              </div>

              <address className="not-italic font-medium">
                Unit 51, Riverside Estate, Sir Thomas Longley Rd, Rochester, ME2 4DP
              </address>
            </div>

            <div className={"hidden desktop:grid grid-cols-2 gap-3 justify-start mt-5 tablet:mx-0 tablet:gap-4 tablet:w-2/3 desktop:gap-8 desktop:mt-0 desktop:w-full"}>
              <Button to="mailto:enquiries@bauvill.co.uk" text="Get In Touch" color={"light"} fixedWidth={true} isExternal={true} responsive={true} />
              <Button to="https://maps.app.goo.gl/5AN8BwU9jQo5gxvQ7" text="Google Maps" color={"light"} fixedWidth={true} isExternal={true} responsive={true} />
            </div>
          </div>
        </nav>

        <div className="grid grid-cols-1 gap-0 tablet:gap-4 desktop:grid-cols-2">
          <div className="flex flex-row-reverse gap-6 mb-0 tablet:flex-row tablet:gap-16 tablet:mb-20 desktop:mb-36">
            <div className="flex flex-col gap-4 justify-start tablet:hidden desktop:flex desktop:justify-end">
              {Object.entries(SOCIAL_MEDIA_LINKS).map(([platform, url]) => (
                <SocialMediaLink key={platform} platform={platform} url={url}/>
              ))}
            </div>

            <div className="grid grid-cols-1 items-end gap-3 tablet:gap-24 tablet:grid-cols-[auto_250px] desktop:gap-6 desktop:grid-cols-[0.8fr_0.4fr_0.4fr]">
              <div className={"flex flex-col gap-4 justify-end"}>
                <p className="text-xs text-light font-medium tablet:text-base tablet:leading-[1.3]">
                  A modern construction company with traditional values. Excellence within the Healthcare, Commercial,
                  Education and Facilities sectors.
                </p>

                <div className={"grid grid-cols-2 gap-2 tablet:gap-6 desktop:hidden"}>
                  <Button to="mailto:enquiries@bauvill.co.uk" text="Email Us" color={"light"} fixedWidth={true}
                          isExternal={true} responsive={true} extraSmallOnMobile={true}/>
                  <Button to="tel:+441622231490" text="Phone Us" color={"light"}
                          fixedWidth={true} isExternal={true} responsive={true} extraSmallOnMobile={true}/>
                </div>
              </div>

              <div className={"flex gap-4"}>
                <div className="hidden flex-col gap-4 justify-end tablet:flex desktop:hidden">
                  {Object.entries(SOCIAL_MEDIA_LINKS).map(([platform, url]) => (
                    <SocialMediaLink key={platform} platform={platform} url={url}/>
                  ))}
                </div>

                <div className={"grid grid-cols-2 gap-4 mt-4 tablet:mt-0 tablet:flex tablet:flex-col tablet:gap-2"}>
                  <address className="not-italic font-medium text-xs tablet:text-base tablet:leading-[1.2] tablet:block desktop:hidden mb-6">
                    Unit 51, Riverside Estate, Sir Thomas Longley Rd, Rochester, ME2 4DP
                  </address>

                  <div className={"flex tablet:gap-2 flex-col"}>
                    <AnimatedLink to="/privacy-policy" text="Privacy Policy"/>
                    <AnimatedLink to="/cookies-policy" text="Cookies Policy"/>
                  </div>

                </div>

              </div>

              <div className="hidden desktop:block">
                <p>Design & Build by</p>
                <AnimatedLink to="https://joepowderham.com" text="Joe Powderham" isExternal={true}/>
              </div>
            </div>
          </div>

          <div>
          <AnimatedLogo visible={enableAnimation} />
            <div className="grid grid-cols-[1.75fr_1fr_1fr_1fr] gap-6 items-center justify-center text-white overflow-hidden mt-3 h-12 tablet:gap-12 tablet:mt-6 tablet:h-20 tablet:px-24 desktop:mt-12 desktop:pl-0 desktop:h-24 dsktop:gap-16">
              <SafetyBadge className="w-full h-full object-contain" />
              <StaticImage
                src="../assets/images/constructionline-badge.png"
                alt="Constructionline Gold Member Badge"
                className="w-full h-full"
                imgStyle={{ objectFit: "contain" }}
                placeholder="none"
              />
              <StaticImage
                src="../assets/images/safecontractor-badge.png"
                alt="SafeContractor Approved Badge"
                className="w-full h-full"
                imgStyle={{ objectFit: "contain" }}
                placeholder="none"
              />
              <StaticImage
                src="../assets/images/ssip-badge.png"
                alt="SSIP Registered Member Badge"
                className="w-full h-full"
                imgStyle={{ objectFit: "contain" }}
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);