import React, { useEffect, useState } from "react";
import classNames from "classnames";
import "./style.scss";

const App = ({ onClick, isOpen, white = false }) => {
  const active = classNames("mobile-menu", {
    open: isOpen,
  });

  return (
    <>
      <span
        className={`navigation-links ${isOpen ? "open" : ""}`}
        style={{ pointerEvents: "auto", cursor: "pointer" }}>
        <button
          aria-label="Toggle Mobile Menu Button"
          className={active}
          onClick={() => {
            onClick((current) => !current);
          }}>
          <div className={`bar bar-one ${white && "white"}`} />
          <div className={`bar bar-two ${white && "white"}`} />
          <div className={`bar bar-three ${white && "white"}`} />
        </button>
      </span>
    </>
  );
};

export default App;
