import BauvillLogo from "../assets/svgs/logo.svg";
import React, {useRef, useEffect, useState} from "react";
import { gsap } from "gsap";

const AnimatedLogo = ({ visible = false }) => {
  const logoRef = useRef(null);
  const [localEnabled, setLocalEnabled] = useState(false);

  useEffect(() => {
    if (visible !== localEnabled && visible){
      setLocalEnabled(true)
    }
  }, [visible]);

  useEffect(() => {
    const logo = logoRef.current;
    if (!logo || !visible) return;

    // Select all elements with class starting with 'letter-'
    const letters = gsap.utils.toArray(logo.querySelectorAll('[class*="letter-"]'));

    gsap.set(letters, {
      yPercent: 120,
      opacity: 1,
    });

    const tl = gsap.timeline();

    letters.forEach((letter, index) => {
      tl.to(letter, {
        yPercent: 0,
        opacity: 1,
        ease: "power1.inOut",
        duration: 0.5
      }, index * 0.075);
    });

    // Clean up
    return () => {
      tl.kill();
    };
  }, [localEnabled]);

  return (
    <div ref={logoRef} style={{ opacity: localEnabled ? 1 : 0 }}>
      <BauvillLogo width="100%" />
    </div>
  );
};

export default AnimatedLogo;