import { Box, Grommet } from "grommet";
import React from "react";
import NavMenu from "../components/NavMenu";
import "../styles/style.scss";
import { theme } from "./theme";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import { useStaticQuery, graphql } from "gatsby";

const Layout = ({ children, footer = true, white = false }) => {
  const {
    wp: { seo },
} = useStaticQuery(graphql`
    query SiteInfoQuery {
        wp {
            seo {
                contentTypes {
                    post {
                        title
                        schemaType
                        metaRobotsNoindex
                        metaDesc
                    }
                    page {
                        metaDesc
                        metaRobotsNoindex
                        schemaType
                        title
                    }
                }
                webmaster {
                    googleVerify
                    yandexVerify
                    msVerify
                    baiduVerify
                }
                schema {
                    companyName
                    personName
                    companyOrPerson
                    wordpressSiteName
                    siteUrl
                    siteName
                    inLanguage
                    logo {
                        sourceUrl
                        mediaItemUrl
                        altText
                    }
                }
                social {
                    facebook {
                        url
                        defaultImage {
                            sourceUrl
                            mediaItemUrl
                        }
                    }
                    instagram {
                        url
                    }
                    linkedIn {
                        url
                    }
                    mySpace {
                        url
                    }
                    pinterest {
                        url
                        metaTag
                    }
                    twitter {
                        username
                        cardType
                    }
                    wikipedia {
                        url
                    }
                    youTube {
                        url
                    }
                }
            }
        }
    }
`);

  return (
    <Grommet
      theme={theme}
      className="grommet-container"
      style={{ position: "relative", width: "100%", overflow: "hidden" }}
      background="transparent"
    >
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="5a2d911e-58f1-47e6-9d62-7b6364a61d83"
          data-blockingmode="auto"
          type="text/javascript"
        ></script>
      </Helmet>
      <SEOContext.Provider value={{ global: seo }}>
        <LayoutGrid white={white} footer={footer}>
          {children}
        </LayoutGrid>
      </SEOContext.Provider>
    </Grommet>
  );
};

const LayoutGrid = ({ children, footer, white }) => {
  return (
    <Box fill="horizontal" background="background">
      <NavMenu white={white} />
      <Box
        align="center"
        justify="center"
        fill="horizontal"
        className={"bg-light"}
        style={{
          zIndex: 1,
        }}
      >
        {children}
      </Box>
      {footer && <Footer />}
    </Box>
  );
};

export default Layout;
