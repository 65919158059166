import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";

const AnimatedLink = ({ to = "#", text, className, isExternal = false }) => {
  const linkRef = useRef(null);
  const underlineRef = useRef(null);

  useEffect(() => {
    const link = linkRef.current;
    const underline = underlineRef.current;

    gsap.set(underline, {
      scaleX: 0,
      transformOrigin: "left center"
    });

    const enterAnimation = () => {
      gsap.set(underline, { transformOrigin: "left center" });
      gsap.to(underline, {
        scaleX: 1,
        duration: 0.4,
        ease: "power2.out"
      });
    };

    const leaveAnimation = () => {
      gsap.set(underline, { transformOrigin: "right center" });
      gsap.to(underline, {
        scaleX: 0,
        duration: 0.4,
        ease: "power2.in"
      });
    };

    link?.addEventListener("mouseenter", enterAnimation);
    link?.addEventListener("mouseleave", leaveAnimation);

    return () => {
      link?.removeEventListener("mouseenter", enterAnimation);
      link?.removeEventListener("mouseleave", leaveAnimation);
    };
  }, []);

  const linkProps = isExternal
    ? { href: to, target: "_blank", rel: "noopener noreferrer" }
    : { to };

  const LinkComponent = isExternal ? "a" : Link;

  return (
    <LinkComponent
      {...linkProps}
      className={`self-start text-light text-xs font-medium relative inline-block tablet:text-base ${className}`}
      ref={linkRef}
    >
      {text}
      <span
        ref={underlineRef}
        className="absolute bottom-0 left-0 w-full h-0.5 bg-current"
      ></span>
    </LinkComponent>
  );
};

export default AnimatedLink;