import React, { useEffect, useState, useRef } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Icon from "./Icon.js";
import SymbolHealth from "../../assets/svgs/healthSymbol.svg";
import SymbolEducation from "../../assets/svgs/educationSymbol.svg";
import SymbolCommercial from "../../assets/svgs/commercialSymbol.svg";
import SymbolFacilities from "../../assets/svgs/facilitiesSymbol.svg";
import { gsap } from "gsap";
import Logo from "../../assets/svgs/logo-current-color.svg";
import LinkedIn from "../../assets/svgs/socialLinkedIn.svg";
import X from "../../assets/svgs/socialX.svg";
import Instagram from "../../assets/svgs/socialInstagram.svg";
import AnimatedLink from "../AnimatedLink";

const Symbols = [
  <SymbolHealth
    className={"absolute top-1/3 left-1/4 -translate-x-1/2 -translate-y-1/2 desktop:scale-125 desktop:top-[20%]"}
    height="125%"
    width="125%"
  />,
  <SymbolCommercial
    className={"absolute top-1/3 left-1/4 -translate-x-1/2 -translate-y-1/2 desktop:scale-110 desktop:top-[30%]"}
    height="125%"
    width="125%"
  />,
  <SymbolEducation
    className={"absolute top-1/3 left-1/4 -translate-x-1/2 -translate-y-1/2 desktop:scale-110 desktop:top-[35%] desktop:left-[20%]"}
    height="125%"
    width="125%"
  />,
  <SymbolFacilities
    className={"absolute top-1/3 left-1/4 -translate-x-1/2 -translate-y-1/3 desktop:scale-110 desktop:top-[35%] desktop:left-[20%]"}
    height="100%"
    width="100%"
  />,
];

const MenuSymbol = ({ active }) => {
  const symbolRef = useRef();
  const activeRef = useRef(false);
  const [currentSymbol, setCurrentSymbol] = useState();

  const tl = useRef(
    gsap.timeline({
      paused: true,
    })
  );

  useEffect(() => {
    activeRef.current = active;
  }, [active]);

  const changeSymbol = () => {
    setCurrentSymbol(activeRef.current);
  };

  useEffect(() => {
    tl.current
      .add(gsap.set(symbolRef.current, { transformOrigin: "0 100%" }))
      .to(symbolRef.current, { opacity: 0, scale: 1.2, duration: 0.25 })
      .call(changeSymbol)
      .to(symbolRef.current, { opacity: 0, scale: 0.85, duration: 0 })
      .to(symbolRef.current, { opacity: 1, scale: 1, duration: 1 });
  }, []);

  useEffect(() => {
    tl.current.invalidate().restart();
  }, [active]);

  return (
    <div
      ref={symbolRef}
      className={"absolute top-0 bottom-0 left-0 right-0"}
    >
      {Symbols[currentSymbol] || null}
    </div>
  );
};

const MenuItem = ({ setHoverActive, index, active, item, setOpen }) => {
  const symbolRef = useRef();

  useEffect(() => {
    if (active) {
      gsap.to(symbolRef.current, {
        left: "15px",
        duration: 0.25,
      });
    } else {
      gsap.to(symbolRef.current, {
        left: "0px",
        duration: 0.25,
      });
    }
  }, [active]);

  const handleClick = () => {
    if (active) {
      setOpen(false);
    }
  };

  return (
    <Link
      to={item.path}
      onClick={handleClick}
      onMouseOver={() => setHoverActive(index)}
      className="w-full text-white no-underline"
    >
      <div className="flex-grow border-b-2 border-white pb-0 pt-3">
        <span
          ref={symbolRef}
          className={`relative text-white m-0 text-2xl leading-[0.8] font-bold tablet:text-7xl desktop:text-nav`}
          style={{ left: 0 }}
        >
          {item.label}
        </span>
      </div>
    </Link>
  );
};

const SocialMediaLink = ({platform = "instagram"}) => {
  let icon = null
  let link = null

  switch (platform) {
    case "linkedin":
      icon = <LinkedIn fill={"currentColor"} />
      link = "https://www.linkedin.com/company/bauvill-ltd./"
      break
    case "x":
      icon = <X />
      link = "https://x.com/bauvill"
      break
    case "instagram":
    default:
      icon = <Instagram />
      link = "https://www.instagram.com/bauvill_ltd/"
  }

  return (
    <a className={"text-white h-7"} href={link} target={"_blank"}>
      <span className={"size-7 *:w-full *:h-full *:transition-[padding] *:duration-200 *:pr-2 hover:*:pl-2 hover:*:pr-0"}>
        {icon}
      </span>
    </a>
  )
}


const NavLogo = ({ whiteStyle = false }) => {
  const color = whiteStyle ? "white" : "#EE0000";

  return (
    <Logo
      className={`w-1/2 transition-color duration-1000 desktop:w-3/4`}
      style={{
        color: color
      }}
    />
  );
}

const Nav = ({ white = false }) => {
  const data = useStaticQuery(
    graphql`
      {
        wpMenu(locations: { eq: PRIMARY_MENU }) {
          id
          menuItems {
            nodes {
              parentId
              label
              path
              childItems {
                nodes {
                  path
                  label
                }
              }
            }
          }
        }
        allWpCategory(filter: { slug: { ne: "uncategorized" } }) {
          nodes {
            uri
            slug
            name
          }
        }
      }
    `
  );

  const {
    wpMenu: { menuItems },
  } = data;

  const [isOpen, setOpen] = useState(false);
  const [hoverActive, setHoverActive] = useState(-1);

  // No scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      // Remove overflow inline style
      document.body.style.removeProperty("overflow");
    }
  }, [isOpen]);

  // On unmount set overflow to auto
  useEffect(() => {
    return () => {
      // Remove overflow inline style
      document.body.style.removeProperty("overflow");
    };
  }, []);

  const renderMenuItems = () =>
    menuItems &&
    menuItems.nodes.map((item, index) => {
      return (
        <MenuItem
          key={index}
          item={item}
          index={index}
          active={hoverActive === index}
          setHoverActive={setHoverActive}
          setOpen={setOpen}
        />
      );
    });

  return (
    <>
      {/*Menu content*/}
      {isOpen && (
        <div className={"content-container bg-secondary  fixed z-20 inset-0"}>
          <MenuSymbol active={hoverActive} />
          {/*Logo*/}
          <Link to={"/"} className={"absolute top-4 z-10 desktop:top-12"}>
            <NavLogo whiteStyle={hoverActive === 3} />
          </Link>

          {/*Content*/}
          <div className={"w-full h-full relative"}>
            {/*Nav links*/}
            <div className={"absolute -translate-y-1/2 top-1/2 left-0 right-0 border-t-2 border-white tablet:left-auto tablet:w-3/4 desktop:w-3/5 desktop:bottom-0"}>
              {renderMenuItems(false)}
            </div>

            {/*Company information*/}
            <div className={"flex justify-between items-end absolute bottom-6 left-0 right-0 desktop:bottom-auto desktop:left-auto desktop:top-12 desktop:w-3/5 desktop:flex-row-reverse desktop:justify-end desktop:items-start desktop:gap-16"}>
              {/*Details*/}
              <div className={"text-sm text-white leading-2 font-medium desktop:flex desktop:items-start desktop:gap-16 desktop:mt-3"}>
                {/*Address*/}
                <address className={"not-italic"}>
                  51 Riverside Estate, <br/>Sir Thomas Longley Rd, <br/>Medway City Estate, <br/>Kent, ME2 4DP
                </address>

                {/*Contact*/}
                <div className={"mt-3 desktop:mt-0"}>
                  <AnimatedLink to={"tel:+441622231490"} text={"t: 01622 231490"} className={"text-white"} isExternal={true} />
                  <br />
                  <AnimatedLink to={"mailto:enquiries@bauvill.co.uk"} text={"enquiries@bauvill.co.uk"} className={"text-white"} isExternal={true} />
                </div>
              </div>

              {/*Social media*/}
              <div className={"grid grid-cols-1 gap-3 text-white"}>
                <SocialMediaLink platform={"x"} />
                <SocialMediaLink platform={"instagram"} />
                <SocialMediaLink platform={"linkedin"} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/*Toggle button*/}
      <div className={"fixed top-4 z-50 right-[1.5rem] xl:right-[10rem] desktop:top-12"}>
        <Icon white={white} onClick={setOpen} isOpen={isOpen} />
      </div>
    </>
  );
};

export default Nav;
